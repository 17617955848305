import React, { memo } from 'react'
// import { useSelector } from 'react-redux'

import carePos from '../assets/rewards/care-pos.jpg'
import toothbrush from '../assets/rewards/toothbrush.jpg'
import truthbrushTracker from '../assets/rewards/truthbrush-tracker.png'
// import iRedeemHealthIcon from '../assets/rewards/iredeemhealth.jpeg'
import {
  zDentalUrl,
  truthbrushUrl
  // iRedeemEarnUrl
} from '../util/brand.helpers'
import EarnRewardCards from '../components/earnPoints/EarnRewardCards'

// @TODO: the following design can be reused for the other LWAs in future.
// For now using only for moda_health LWA.
const HowToEarnRewardsWrapper = ({ t, tokenSymbol, ...restProps }) => {
  // const authId = useSelector(
  //   (state) => state.accountReducer.accountProfile.authId
  // )
  return (
    <EarnRewardCards
      data={[
        {
          label: 'buy-dental-and-vision',
          description: `$1 = 10 ${tokenSymbol}`,
          image: carePos,
          link: 'https://moda.telemedsimplified.com'
        },
        {
          label: 'buy-zdental',
          description: `$1 = 10 ${tokenSymbol}`,
          image: toothbrush,
          link: zDentalUrl()
        },
        {
          label: 'buy-truthbrush-tracker',
          description: `$1 = 10 ${tokenSymbol}`,
          image: truthbrushTracker,
          link: truthbrushUrl()
        },
        {
          label: 'brush-your-teeth',
          description: `10 ${tokenSymbol} / day`,
          image: truthbrushTracker,
          link: truthbrushUrl()
        }
        // @TODO: Enable for moda iRedeem whenever webhook setup is ready
        // , {
        //   label: 'iredeemhealth',
        //   description: `$1 = 10 ${tokenSymbol}`,
        //   image: iRedeemHealthIcon,
        //   link: iRedeemEarnUrl(authId)
        // }
      ]}
      t={t}
      {...restProps}
    />
  )
}

export default memo(HowToEarnRewardsWrapper)
